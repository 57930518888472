import slide1 from './images/slide-1.webp'
import slide2 from './images/slide-2.webp'
import slide3 from './images/slide-3.webp'
import logo from './images/mainLogo.webp'
import './Carousel.css';
// import {HashLink} from 'react-router-hash-link'

function Header() {
  return (
    <div className='header'>


<div id="demo" className="carousel slide" data-bs-ride="carousel">

  <div className="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>
  
  <div className="carousel-inner">
    <div className='hero-image'><h1>Welcome to Bare By Kristen </h1>
    <p>The Best Waxing Services in New Jersy (NJ)</p>
    <img src={logo} alt="Bare by Kristen Logo" className="hero-logo" />
    </div>
    <div className="carousel-item active">
      <img src={slide1} alt="Brazilian Waxing" className="d-block imgaa" />
    </div>
    <div className="carousel-item">
      <img src={slide2} alt="Waxing in NJ" className="d-block imgaa" />
    </div>
    <div className="carousel-item">
      <img src={slide3} alt="Men Waxing Near Me" className="d-block imgaa" /> 
    </div>
      </div>
  
  <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span className="carousel-control-next-icon"></span>
  </button>
</div>
       </div>
  )
}

export default Header
