import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-6">
            <p>&copy; {currentYear} Bare by Kristen</p>
          </div>
          <div className="col-md-6">
            <p>
              Address: 40 West Palisade Ave. Suite 201<br />
              Englewood, NJ 07631
            </p>
            <p>Contact: (201) 888.2050</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
