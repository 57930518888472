import './WhyChooseUs.css';

const WhyChooseUs = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-4 text-danger why">Why Choose me?</h2>
      <div className="row justify-content-around">
        <div className="col-md-5 point animated fadeInLeft">
          <h3 className='whyh'>Expert Esthetician</h3>
          <p>
          I am a licensed and certified esthetician with many years of experience in the art of waxing. I am committed to delivering precise and efficient waxing services while ensuring your comfort throughout the process. I am a true expert and Brazilian Waxing is my specialty! You will be extremely happy here.
          </p>
        </div>
        <div className="col-md-5 point animated fadeInRight">
          <h3 className='whyh'>High-Quality Products</h3>
          <p>
          I use all natural premium waxing products that are gentle on the skin and highly effective in removing unwanted hair. These products minimize discomfort and reduce the risk of irritation. My wax is amazing for sensitive skin.
          </p>
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="col-md-5 point animated fadeInRight">
          <h3 className='whyh'>Hygienic Environment</h3>
          <p>
          Your safety is my priority. I maintain the highest standards of cleanliness and hygiene, ensuring that all equipment and treatment areas are thoroughly sanitized between each client. No Double Dipping! A new stick is used after every time it touches the skin!
          </p>
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="col-md-5 point animated fadeInLeft">
          <h3 className='whyh'>Comfort and Privacy</h3>
          <p>
          My waxing studio is extremely unique. Amazing vibes and something you have to come experience for yourself. My cozy and private treatment room is designed to make you feel at ease during your waxing session. I want you to relax and enjoy the experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
