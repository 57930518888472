import React from "react";
import logo from "./images/mainLogo.png";
import {
  FaHome,
  FaInfoCircle,
  FaBriefcase,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaGoogle,
} from "react-icons/fa";
// import { Link } from 'react-router-dom'
import { HashLink } from "react-router-hash-link";

const myStyle = {
  color: "red",
  textDecoration: "none",
  fontWeight: "bold",
};

const Menu = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <HashLink to="#home" smooth>
        <a className="navbar-brand" href="#home">
          <img
            src={logo}
            alt="Bare By Kristen Logo"
            width="80"
            height="80"
            id="home"
          />
        </a>
      </HashLink>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <HashLink to="#home" smooth>
              <a className="nav-link" href="#home" style={myStyle}>
                <FaHome /> Home
              </a>
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink to="#about" smooth>
              <a className="nav-link" href="#about" style={myStyle}>
                <FaInfoCircle /> About
              </a>
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink to="#services" smooth>
              <a className="nav-link" href="#services" style={myStyle}>
                <FaBriefcase /> Wax Services
              </a>
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink to="#contact" smooth>
              <a className="nav-link" href="#contact" style={myStyle}>
                <FaEnvelope /> Contact
              </a>
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              to="https://www.google.com/search?q=bare+by+kristen&oq=bare+by+kri&gs_lcrp=EgZjaHJvbWUqDwgAECMYJxjjAhiABBiKBTIPCAAQIxgnGOMCGIAEGIoFMhIIARAuGCcYrwEYxwEYgAQYigUyBggCEEUYOTIHCAMQABiABDIKCAQQABgKGBYYHjIICAUQABgWGB4yBggGEEUYPDIICAcQABgWGB4yDQgIEAAYhgMYgAQYigUyDQgJEAAYhgMYgAQYigUyDQgKEAAYhgMYgAQYigUyDQgLEAAYhgMYgAQYigUyCggMEAAYgAQYogQyCggNEAAYgAQYogTSAQkyNTQ5OGowajSoAg6wAgE&client=ms-android-transsion&sourceid=chrome-mobile&ie=UTF-8#wptab=si:ACC90nzZwgO0P4Qu3-A0NldYnwAKwrAmvjJXmiihL2mEqps3A4zTWFsjqsha3s0PLTcX7ZBQjsQ_Rwkc-1UECUOs0_WN-0vKJkoCWFiJUo1BOnajLotok00ZzSmkt5qSIb3Viidk845N"
              smooth
            >
              <a
                className="nav-link"
                style={myStyle}
                href="https://www.google.com/search?q=bare+by+kristen&oq=bare+by+kri&gs_lcrp=EgZjaHJvbWUqDwgAECMYJxjjAhiABBiKBTIPCAAQIxgnGOMCGIAEGIoFMhIIARAuGCcYrwEYxwEYgAQYigUyBggCEEUYOTIHCAMQABiABDIKCAQQABgKGBYYHjIICAUQABgWGB4yBggGEEUYPDIICAcQABgWGB4yDQgIEAAYhgMYgAQYigUyDQgJEAAYhgMYgAQYigUyDQgKEAAYhgMYgAQYigUyDQgLEAAYhgMYgAQYigUyCggMEAAYgAQYogQyCggNEAAYgAQYogTSAQkyNTQ5OGowajSoAg6wAgE&client=ms-android-transsion&sourceid=chrome-mobile&ie=UTF-8#wptab=si:ACC90nzZwgO0P4Qu3-A0NldYnwAKwrAmvjJXmiihL2mEqps3A4zTWFsjqsha3s0PLTcX7ZBQjsQ_Rwkc-1UECUOs0_WN-0vKJkoCWFiJUo1BOnajLotok00ZzSmkt5qSIb3Viidk845N"
              >
                <FaGoogle /> GOOGLE REVIEWS
              </a>
            </HashLink>
          </li>
        </ul>
      </div>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a
            className="nav-link"
            href="https://web.facebook.com/barebykristen/?_rdc=1&_rdr"
          >
            <FaFacebook style={myStyle} />
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="https://www.instagram.com/barebykristen/?hl=en"
          >
            <FaInstagram style={myStyle} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
