// import { Carousel } from 'bootstrap';
import './App.css';
import Menu from './menu.js';
import Carousel from './Carousel.js';
import AboutUs from './AboutUs.js';
import WhyChooseUs from './WhyChooseUs.js';
import Services from './Services.js';
// import GoogleReviews from './GoogleReview.js';
import GoogleReviewSlider from './GoogleReview.js';
import Footer from './Footer.js';
import ContactUs from './ContactUs.js';
import {BrowserRouter as Router} from "react-router-dom"


function App() {
  return (
    <Router>
    <div className="App">
      <Menu />
      <Carousel />
      <AboutUs />
      <WhyChooseUs />
      <Services />
      <GoogleReviewSlider />
      <ContactUs />
      <Footer />
    </div>
    </Router>
  );
}

export default App;
