
// import { BsArrowRight } from 'react-icons/bs'; // Import arrow icons

const servicesData = [
  { service: "Men's Brazilian waxing", price: "$75" },
  { service: "Women's Brazilian Bikini waxing", price: "$60" },
  { service: "Full Bikini", price: "$55" },
  { service: "Bikini Line", price: "$15" },
  { service: "Arm (Full)", price: "$50" },
  { service: "Arm (Half)", price: "$25" },
  { service: "Full Legs", price: "$95" },
  { service: "Upper Legs (Thighs)", price: "$65" },
  { service: "Lows Legs (Calves)", price: "$45" },
  { service: "Butt", price: "$30" },
  { service: "Stomach", price: "$40" },
  { service: "Stomach (Strip)", price: "$15" },
  { service: "Chest (Full)", price: "$30" },
  { service: "Chest (Strip)", price: "$15" },
  { service: "Back waxing (Full)", price: "$70" },
  { service: "Back (Lower)", price: "$25" },
  { service: "Shoulders", price: "$20" },
  { service: "Underarms", price: "$15" },
  { service: "Toes", price: "$15" },
  { service: "Nose, Lip, Neck, Ears, Chin, Cheeks, Sideburns", price: "$15" },
];

const Services = () => {
  return (
    <div id="services" className="container my-5">
      <h2 className="text-center text-danger why">SERVICES</h2>
      <div className="list-group">
        {servicesData.map((service, index) => (
          <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
            <span>{service.service}</span>
            <span>{service.price}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
