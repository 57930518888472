import React from 'react';
import kristen from './images/kristen.jpeg'
import './Aboutus.css'; // Import your CSS file

const AboutUs = () => {
  return (
    <div id="about" className="about-us">
      <div className="image">
        <img src={kristen} alt="Kristen" />
      </div>
      <div className="text">
        <h2 className='about-h'>About Me</h2>
        <p className='about-paragraph'>
        The licensed aesthetician behind <span> BARE by Kristen </span> specializes in making skin hairless and satin-smooth. I remove unwanted strands of hair with hard wax and gel wax that’s amazing for sensitive skin. My wax is free of plastic-based fillers, preventing issues such as ingrown hairs. I also enforce a high standard of cleanliness, using a brand-new wax stick each time I touched a client’s skin. I am extremely thorough and as painless as it comes! Although I can skillfully wax hair from arms, legs, chest, back, and other regions of the body, I specialize in the Brazilian bikini wax. I am a true EXPERT!. By far, this will be the BEST Brazilian waxing experience of your lifetime!! BEST Brazilian Waxing Bergen County, NJ!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
