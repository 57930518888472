import React from 'react';

function ContactUs() {
  return (
    <div id='contact' className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <h2>Contact Me</h2>
          <p>If you would like to make a reservation for my services, please text or call (201) 888.2050 and include the following:</p>
          <ul>
            <li>Your full name</li>
            <li>Service(s) you are interested in</li>
            <li>How you heard about me</li>
            <li>The date and time that works for you</li>
          </ul>
          <p>I will get back to you shortly to confirm your request. Texting is the best way to reach me.</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
